export default [
  {
    path: '/vote',
    name: 'vote',
    component: () => import('@/views/vote/Vote.vue'),
    meta: {
      layout: 'full',
      middleware: ['Auth'],
      // middleware: ['Auth', 'AuthTokenVote'],
      footer: false,
      // isMobile: true,
    },
  },
  {
    path: '/vote/success',
    name: 'success-vote',
    component: () => import('@/views/successPage/SuccessPage.vue'),
    meta: {
      layout: 'full',
      middleware: 'Auth',
    },
  },
  {
    path: '/vote/coming',
    name: 'coming-vote',
    component: () => import('@/views/comingVote/ComingVote.vue'),
    meta: {
      layout: 'full',
      middleware: 'Auth',
    },
  },
  // rekapulasi untuk admin
  // {
  //   path: '/vote/recapulations',
  //   name: 'recapulation',
  //   component: () => import('@/views/recapulation/Recapulation.vue'),
  //   meta: {
  //     layout: 'full',
  //     footer: false,
  //     middleware: 'Auth',
  //   },
  // },
  {
    path: '/vote/recapulations/general',
    name: 'recapulation-general',
    component: () => import('@/views/recapulationGeneral/Recapulation.vue'),
    meta: {
      layout: 'full',
      footer: false,
      middleware: 'Auth',
    },
  },
]
