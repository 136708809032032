import {
  TOKEN_CURRENT,
} from '@/constants/index'
import storage from './storage'

const {
  VUE_APP_SECRET_KEY_CRYPTO_JS,
} = process.env

const CryptoJS = require('crypto-js')

const defaultObjOfUser = {
  tokenCurrent: '',
  isOtp: true,
  roleId: 0,
  tokenVote: '',
  deviceInfo: '',
  appVersion: '',
  apiVersion: '',
  platform: '',
}

export const setUserStorage = (data = defaultObjOfUser) => { // isOtp true menandakan token nya sudah tervalidasi otp.
  const tokenEncrypted = CryptoJS.AES.encrypt(JSON.stringify(data), VUE_APP_SECRET_KEY_CRYPTO_JS).toString()

  storage.setStorage(TOKEN_CURRENT, tokenEncrypted)
}

const getUserDataFromStorage = () => {
  try {
    const tokenEncrypted = storage.getStorage(TOKEN_CURRENT)
    const bytes = CryptoJS.AES.decrypt(tokenEncrypted, VUE_APP_SECRET_KEY_CRYPTO_JS)
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
  } catch (error) {
    return defaultObjOfUser
  }
}

export default getUserDataFromStorage
